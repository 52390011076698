export default function sliders(Swiper) {
    const swiper = new Swiper('.swiper', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 30,

        keyboard: {
          enabled: true,
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            // when window width is >= 768px
            992: {
                slidesPerView: 3,
                slidesPerGroup: 3
            }
        }
    });
 }
