export default function mobileMenu() {
    $(document).on('click', '.toggle-mobile-menu', function() {
        $('#mobile-menu').toggleClass('show');
    });

    let selectLanguage = document.getElementById('language-select');
    if (selectLanguage) {
        selectLanguage.onchange = (event) => {
            window.location = event.target.value;
        }
    }
}
