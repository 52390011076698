export default function jsSameHeight() {
    let jsSameHeightArray = {};
    let windowWidth = $(window).outerWidth();
    $('.js-same-height').css('height', 'auto');

    $('.js-same-height').each(function() {
        let dataKey = $(this).data('key');
        let dataBreakpoint = $(this).data('breakpoint');

        if (dataBreakpoint && dataBreakpoint == 'md' && windowWidth < 768) return;

        if (!jsSameHeightArray[dataKey] || jsSameHeightArray[dataKey] < $(this).outerHeight()) {
            jsSameHeightArray[dataKey] = $(this).outerHeight();
        }
    });

    for (const [key, value] of Object.entries(jsSameHeightArray)) {
        $('[data-key="'+ key +'"]').css('height', value);
    }
}
