export default function searchFilters() {
    $(document).on('change', '.checkbox-group-master input', function() {
        let children = $(this).closest('.checkbox-group').find('.checkbox-group-slaves input');
        children.prop('checked', $(this).is(':checked'));
    });

    $(document).on('click', '.search-reset', function() {
        let $searchQuery = $('.search-query');
        $searchQuery.val('');
        $searchQuery.trigger('focus');

    });

    let resize = function() {
        let windowWidth = $(window).outerWidth();

        if (windowWidth < 992) {
            $('.search-filters-wrapper-mobile').append($('.search-filters-form'));
        } else {
            $('.search-filters-wrapper-desktop').append($('.search-filters-form'));
        }
    }

    $(window).on('resize', resize);
    resize();
}
