/**
 * Back to top JS
 */
export default function back2top() {
    const $ = require('jquery');
    const $backToTop = $('#back2top');

    if ($backToTop.length) {
	    $backToTop.removeClass('active');
	    $(window).scroll(function() {
	        if ($(this).scrollTop() > 100) {
	            $backToTop.addClass('active');
	        } else {
	            $backToTop.removeClass('active');
	        }
	    });
	}
}
