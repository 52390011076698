'use strict';
import './js/vendor/jquery';
import './js/vendor/bootstrap';

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import './css/styles.scss';

import { OnlineServiceProfileSelector } from './js/modules';

import adminMenu from '@elasticms/admin-menu';
import back2top from "./js/back2top";
import ajaxSearch from "./js/ajax-search";
import toc from "./js/toc";
import externalLink from "./js/external-link";
import form from "./js/form";
import {NavBar} from "./js/navbar";
import multilevelNavbar from "./js/multilevel-navbar";
import newsFilters from "./js/news-filters";
import jsSameHeight from "./js/same-height";
import sliders from "./js/sliders";
import datepicker from "./js/datepicker";
import searchFilters from "./js/search";
import mobileMenu from "./js/mobile-menu";
import wysiwygContent from "./js/wysiwyg-content";

const translations = JSON.parse(document.body.getAttribute('data-translations'));

adminMenu('esm_demo_admin', '<i class="ems-icon" aria-hidden="true"></i><span class="sr-only">'+ (translations.back_to_ems === undefined ? 'Back to ems' : translations.back_to_ems) + '</span>');
back2top();
ajaxSearch();
toc();
externalLink('<i class="iwita-extern-link ml-1" aria-hidden="true"></i><span class="sr-only">'+ (translations.target_blank === undefined ? 'Back to ems' : translations.target_blank) + '</span>');
form();
multilevelNavbar();
mobileMenu();
wysiwygContent();

const navBar = new NavBar();
// navBar.activateBestItem();

window.onload = () => {
    new OnlineServiceProfileSelector('[data-online-service-profile-selector]');
    jsSameHeight();
}

function resize() {
    jsSameHeight();
}

$(window).on('resize', resize);

$(document).ready(function() {
    sliders(Swiper);
    datepicker();
    searchFilters();
});
