const $ = require('jquery');
window.jQuery = $;

require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.nl')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.de');

export default function datepicker() {
    $('.datepicker').each(function () { $(this).datepicker('destroy'); });
    $('div.date-range').each(function () {
       let inputClass = $(this).data('input-class');

        $(this).datepicker({
            format: 'dd/mm/yyyy',
            theme: 'bootstrap',
            todayHighlight: true,
            autoclose: true,
            language: $('html').attr('lang'),
            clearBtn: true,
            inputs: $('.' + inputClass),
            keepEmptyValues: true
        });

        $(this).find('.btn-show-datepicker').on('click', function() {
            let datepickerId = $(this).data('datepicker');
            $(datepickerId).datepicker('show');
        });
    });
}
