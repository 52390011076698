export default function wysiwygContent(mainSelector = '.wysiwyg-content') {
    // tables
    $(mainSelector).find('table').each(function() {
        if ($(this).parent().hasClass('table-responsive')) return;

        $(this).wrap('<div class="table-responsive"></div>');
        $(this).addClass('table')
        $(this).find('thead').addClass('thead-light');
    });

    const iconLinks = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'zip'
    ];
    $(mainSelector).find('a').each(function() {
        let href = $(this).attr('href');
        if (iconLinks.some(ext => href.endsWith(ext))) {
            $(this).attr('target', '_blank');
        }
    });
}
