'use strict';

export class OnlineServiceProfileSelector
{
    #selector;

    constructor (selector) {
        this.#selector = selector ?? '[data-online-service-profile-selector]';
        this.init();
    }

    init() {
        document.addEventListener('load', this.execute(event));
    }

    execute(event) {
        const profileSelector = document.querySelector(this.#selector);
        if (profileSelector) {
            let endpoints = profileSelector.dataset.profilesEndpoints;
            endpoints = JSON.parse(endpoints);

            const choices = profileSelector.querySelectorAll('[data-choice]');
            const button = profileSelector.querySelector('a[data-button]');
            button.classList.add('disabled');

            choices.forEach(choice => choice.addEventListener('change', () => {
                const endpoint = endpoints[choice.value];
                if (endpoint) {
                    button.setAttribute('href', endpoint);
                    button.classList.remove('disabled');
                }
            }));
        }
    }
}